import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode"
import { ButtonWhite } from "../common/buttonWhite/buttonWhite"
import { setShowScanPage, clearScanData } from "./barcodeScannerSlice.js"
import { getOrdersData } from "./barcodeScannerSlice"
import "./barcodeScanner.scss"
import { set } from "react-hook-form"

export function BarcodeScanner() {
  const [scanResult, setScanResult] = useState(null)
  const [manualNumber, setNumberManual] = useState("")
  const [searchedValue, setSearchedValue] = useState("")
  const [scanner, setScanner] = useState(null)
  const { token } = useSelector((state) => state.loginOTP)
  const { getOrdersDataSuccess, getOrdersDataFailed, ordersData } = useSelector(
    (state) => state.barcodeScanner
  )
  const dispatch = useDispatch()

  useEffect(() => {
    let scn = new Html5QrcodeScanner("reader", {
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      qrbox: {
        width: 400,
        height: 300,
      },
      fps: 5,
    })
    setScanner(scn)
    return () => {
      setScanResult(null)
      dispatch(clearScanData())
    }
  }, [])

  useEffect(() => {
    if (scanner) {
      scanner.render(success, error)
      function success(result) {
        setScanResult(result)
      }
      function error(err) {
        console.warn(err)
      }
    }
  }, [scanner])

  useEffect(() => {
    if (!scanResult || scanResult == ordersData) {
      return
    }
    setSearchedValue(scanResult)
    dispatch(
      getOrdersData({
        token: token,
        searchTerms: scanResult,
      })
    )
  }, [scanResult])

  useEffect(() => {
    const timeout = setTimeout(() => {}, 500)
    return () => clearTimeout(timeout)
  }, [getOrdersDataSuccess])

  const onClose = () => {
    if (scanner) {
      scanner.clear()
      setScanner(null)
    }
    setNumberManual("")
    dispatch(setShowScanPage(false))
  }

  const onManualInput = (number) => {
    setSearchedValue(number)
    dispatch(
      getOrdersData({
        token: token,
        searchTerms: number,
      })
    )
  }

  const DisplayOrderDetails = ({ data }) => {
    return (
      <div>
        {data.map((order, index) => (
          <div key={index} className="table-wrapper">
            <table>
              <tbody>
                <tr>
                  <th>מספר הזמנה</th>
                  <td>{order.orderNumber}</td>
                </tr>
                <tr>
                  <th>מספר חבילה</th>
                  <td>{order.packageNumber}</td>
                </tr>
                <tr>
                  <th>מספר חיצוני</th>
                  <td>{order.externalOrderNumber}</td>
                </tr>

                <tr>
                  <th>סטטוס הזמנה / Order status</th>
                  <td>
                    {order.orderStatusName} / {order.orderStatusName_en}
                  </td>
                </tr>
                <tr>
                  <th>סוג הזמנה</th>
                  <td>{order.orderTypeName}</td>
                </tr>
                <tr>
                  <th>סוג חשבון</th>
                  <td>{order.accountTypeName}</td>
                </tr>
                <tr>
                  <th>קוד אזור</th>
                  <td>{order.routeAreaCode}</td>
                </tr>
                <tr>
                  <th>שם מסלול</th>
                  <td>{order.routeName}</td>
                </tr>
                <tr>
                  <th>מספר עמדה</th>
                  <td>{order.stationNumber}</td>
                </tr>
                <tr>
                  <th>שם עמדה / Station name</th>
                  <td>
                    {order.stationName}
                    {order.stationName_en ? ` / ${order.stationName_en}` : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="barcodeScanner">
      <div className="barcode-wrapper">
        <div>
          <div className="header">
            <div className="btn-close">
              <ButtonWhite text="סגור" clickHandler={() => onClose()} />
            </div>
            <h1>סריקת הזמנות</h1>
          </div>

          <div>
            <div id="reader" className="barcode-reader"></div>
            <p className="center-text">או הכנס את מספר ידני:</p>
            <div className="center-input">
              <input
                type="text"
                value={manualNumber}
                onChange={(evt) => setNumberManual(evt.target.value)}
              />
              <div className="btn-send">
                <ButtonWhite
                  text="שלח"
                  clickHandler={() => onManualInput(manualNumber)}
                />
              </div>
            </div>
            {searchedValue ? <div>נסרק: {searchedValue}</div> : null}
          </div>
          {getOrdersDataSuccess ? (
            <div>
              {ordersData.length == 1 ? <h3>פרטי הזמנה: </h3> : null}
              {ordersData.length > 1 ? (
                <h3>נמצאו {ordersData.length} הזמנות התואמות לבקשתך:</h3>
              ) : null}
              <DisplayOrderDetails data={ordersData} />
            </div>
          ) : null}
          {(getOrdersDataSuccess && ordersData.length == 0) ||
          getOrdersDataFailed ? (
            <div className="order-not-found">
              הזמנה לא נמצאה, אנא פנה לשירות הלקוחות.
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
