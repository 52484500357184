import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const sendOTP = createAsyncThunk(
  "user/sendVerifyCode",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/User/send_verify_code/${phoneNumber}`
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const verifyOTP = createAsyncThunk(
  "user/verifyCode",
  async (userInput, { rejectWithValue }) => {
    const verifyDto = {
      mobilePhone: userInput.phoneNumber,
      verifyCode: userInput.otp,
    }
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_KEY}/User/verify_code`,
        verifyDto,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getUserData = createAsyncThunk(
  "login/getUserData",
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/User/${token}`
      )

      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const loginOTPSlice = createSlice({
  name: "login",
  initialState: {
    loading: false,
    loginSuccess: false,
    loginFail: false,
    token: null,
    getUSerSuccess: false,
    getUserFail: false,
    userData: null,
  },
  reducers: {
    clearUserData: (state, { payload }) => {
      state.userData = null
      state.loginSuccess = false
      state.loginFail = false
      state.getUSerSuccess = false
      state.getUSerFail = false
      state.loading = false
      state.token = null
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(sendOTP.pending, (state) => {
        state.loading = true
      })
      .addCase(sendOTP.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.loading = false
      })
      .addCase(verifyOTP.pending, (state) => {
        state.loading = true
        state.loginSuccess = false
        state.loginFail = false
        state.loginData = null
      })
      .addCase(verifyOTP.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loginSuccess = true
        state.token = payload?.token
        state.loginFail = false
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.loading = false
        state.loginSuccess = false
        state.loginFail = true
        state.loginData = null
      })
      .addCase(getUserData.pending, (state) => {
        state.loading = true
        state.getUSerSuccess = false
        state.getUserFail = false
      })

      .addCase(getUserData.fulfilled, (state, { payload }) => {
        state.loading = false
        state.getUSerSuccess = true
        state.getUserFail = false
        state.userData = payload
      })

      .addCase(getUserData.rejected, (state, { payload }) => {
        state.loading = false
        state.getUserFail = true
        state.getUSerSuccess = false
      })
  },
})

export const { clearUserData } = loginOTPSlice.actions

export default loginOTPSlice.reducer
