import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  setSelectedStation,
  setOrderDetailsClicked,
  postponeStation,
} from "./stationsSlice"
import { endDriverRouteSessions } from "../routes/routesSlice.js"
import { useNavigate } from "react-router"
import { SingleStation } from "./singleStation/singleStation"
import { setShowOrders } from "../orders/ordersSlice.js"
import { setMapView } from "../driverMap/driverMapSlice"
import { NotificationPopup } from "../common/notificationPopup/notificationPopup"
import { ButtonWhite } from "../common/buttonWhite/buttonWhite"
import { YesNoPopup } from "../common/yesNoPopup/yesNoPopup"
import noLocation from "../../assets/images/noLocation.png"
import "./stations.scss"

export function Stations() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    stationsList,
    prevStationList,
    postPoneStationSuccess,
    postPoneStationFailed,
  } = useSelector((state) => state.stations)
  const { token } = useSelector((state) => state.loginOTP)
  const { selectedRouteId, endDriverSessionSuccess, showRoutes } = useSelector(
    (state) => state.routes
  )
  const { showDriverMap, isLocationNotEvailable } = useSelector(
    (state) => state.driverMap
  )
  const [showNotofocation, setShowNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState(false)
  const { startDayTime, currentLanguage } = useSelector((state) => state.main)
  const [searchTerm, setSearchTerm] = useState(null)
  const [sortedStationsList, setSortedStationsList] = useState(null)
  const [filteredStationsList, setFilteredStationsList] = useState(null)
  const [isShowEndOfDayConfirm, setIsShowEndOfDayConfirm] = useState(false)
  const [isShowPostponeSuccess, setIsShowPostponeSuccess] = useState(false)
  const [isShowPostponeFailed, setIsShowPostponeFailed] = useState(false)

  useEffect(() => {
    const recentlyAddedStations = checkForNewOrders(
      prevStationList,
      stationsList
    )
    if (recentlyAddedStations) {
      const newStationsWithOrders = recentlyAddedStations.map((item) => (
        <li> {item.cityName + ", " + item.name} </li>
      ))

      const message = (
        <div className="notification-msg">
          <div>נכנסה הזמנה חדשה לעמדה: </div>
          <div>{newStationsWithOrders}</div>
        </div>
      )
      setNotificationMessage(message)
      setShowNotification(true)
    }
  }, [stationsList])

  useEffect(() => {
    if (stationsList?.length > 0) {
      const sortedStations = customSortStations()
      setSortedStationsList(sortedStations)
    }
  }, [stationsList])

  useEffect(() => {
    if (stationsList?.length > 0 && searchTerm) {
      const filteredStations = sortedStationsList.filter(
        (station) =>
          String(station.stationNumber).includes(searchTerm) ||
          station.address?.includes(searchTerm) ||
          station.address_en?.includes(searchTerm) ||
          station.name?.includes(searchTerm) ||
          station.name_en?.includes(searchTerm)
      )
      setFilteredStationsList(filteredStations)
    } else {
      setFilteredStationsList(sortedStationsList)
    }
  }, [searchTerm, sortedStationsList])

  useEffect(() => {
    if (endDriverSessionSuccess) {
      setIsShowEndOfDayConfirm(false)
    }
  }, [endDriverSessionSuccess])

  useEffect(() => {
    if (postPoneStationSuccess) {
      setIsShowPostponeSuccess(true)
    }
  }, [postPoneStationSuccess])

  useEffect(() => {
    if (postPoneStationFailed) {
      setIsShowPostponeFailed(true)
    }
  }, [postPoneStationFailed])

  const showOrders = (selectedStation) => {
    dispatch(setSelectedStation(selectedStation))
    dispatch(setOrderDetailsClicked(true))
    dispatch(setShowOrders(true))
  }

  const setStationView = (coordinates) => {
    dispatch(setMapView(coordinates))
  }

  const checkForNewOrders = (prevStations, stationsList) => {
    if (
      !prevStations ||
      !stationsList ||
      prevStations.length == 0 ||
      stationsList.length == 0
    ) {
      return null
    }

    let results = stationsList.filter(
      ({ id, pickupDeliveryCount, pickupLaundryCount, routePlanningStatus }) =>
        prevStations.find(
          (prev) =>
            (routePlanningStatus == 3 &&
              prev.id == id &&
              prev.ordersTotal == 0 &&
              pickupDeliveryCount > 0) ||
            (prev.id == id && prev.ordersTotal == 0 && pickupLaundryCount > 0)
        )
    )

    if (!results || results.length == 0) {
      return null
    }
    return results
  }

  const holdStationToNextDay = (stationId) => {
    dispatch(
      postponeStation({
        token: token,
        stationId,
        routeId: selectedRouteId,
      })
    )
  }

  const customSortStations = () => {
    if (stationsList?.length > 0) {
      const stationsCopy = [...stationsList]

      return stationsCopy.sort((a, b) => {
        const aCondition = a.ordersTotal === 0 && a.routePlanningStatus === 2
        const bCondition = b.ordersTotal === 0 && b.routePlanningStatus === 2

        if (aCondition && !bCondition) return 1
        if (!aCondition && bCondition) return -1
        return 0
      })
    } else {
      return null
    }
  }

  const renderStations = () => {
    if (!filteredStationsList) {
      return <div className="station-empty">אין רשומות</div>
    }
    return filteredStationsList?.map((station) => {
      return (
        <SingleStation
          stationData={station}
          key={station.id}
          currentLanguage={currentLanguage}
          showOrders={(selectedStation) => showOrders(selectedStation)}
          setStationView={(coordinates) => setStationView(coordinates)}
          holdStationToNextDay={(stationId) => holdStationToNextDay(stationId)}
          showMapIcons={showDriverMap}
        />
      )
    })
  }

  if (showRoutes) {
    return null
  }

  return (
    <div
      className={`stations-wrapper ${
        showDriverMap ? "with-map" : "without-map"
      }  `}
    >
      <div className="stations-container">
        <div className="header">
          {showDriverMap ? (
            <div>
              <ButtonWhite
                text="סיים את היום"
                clickHandler={() => setIsShowEndOfDayConfirm(true)}
              />
            </div>
          ) : null}

          <div className="search">
            <span>חפש תחנה: </span>
            <input
              type="text"
              placeholder=""
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {isLocationNotEvailable ? (
              <img className="no-location" src={noLocation} alt="no location" />
            ) : null}
          </div>
        </div>

        <div className="line"></div>
        <div className="stations-list">{renderStations()}</div>
      </div>

      <NotificationPopup
        show={showNotofocation}
        text={notificationMessage}
        close={() => setShowNotification(false)}
      ></NotificationPopup>
      <NotificationPopup
        show={isShowPostponeSuccess}
        text={"העמדה נדחתה בהצלחה"}
        close={() => setIsShowPostponeSuccess(false)}
      ></NotificationPopup>
      <NotificationPopup
        show={isShowPostponeFailed}
        text={"שגיא, העמדה לא נדחתה"}
        close={() => setIsShowPostponeFailed(false)}
      ></NotificationPopup>
      <YesNoPopup
        show={isShowEndOfDayConfirm}
        text={"האם אתה בטוח שברצונך לסיים את היום?"}
        close={() => setIsShowEndOfDayConfirm(false)}
        confirm={() =>
          dispatch(
            endDriverRouteSessions({
              token: token,
              routeId: selectedRouteId,
            })
          )
        }
      />
    </div>
  )
}
