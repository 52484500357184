import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Routes } from "../../features/routes/routes.js"
import { Stations } from "../../features/stations/stations.js"
import { DriverMap } from "../../features/driverMap/driverMap.js"
import { getCurrentLocation } from "../../utils/location.js"
import { getCurrentTimeUnixFormat } from "../../utils/formatDate.js"
import {
  getStationsByRoute,
  setShowStations,
  clearStationData,
} from "../../features/stations/stationsSlice"
import { Header } from "../../features/common/header/header.js"
import { Orders } from "../../features/orders/orders.js"
import { useNavigate } from "react-router"
import { ButtonWhite } from "../../features/common/buttonWhite/buttonWhite"
import { NotificationPopup } from "../../features/common/notificationPopup/notificationPopup"
import { BarcodeScanner } from "../../features/barcodeScanner/barcodeScanner"
import {
  endDriverRouteSessions,
  setShowRoutes,
  clearEndDriverSessions,
} from "../../features/routes/routesSlice"
import { clearUserData } from "../loginOtpPage/loginOTPSlice"
import {
  setShowDriverMap,
  setCurrentLocation,
} from "../../features/driverMap/driverMapSlice"
import truck from "../../assets/images/truck.png"
import {
  setLocationError,
  setLocationNotEvailable,
} from "../../features/driverMap/driverMapSlice"
import "./mainPage.scss"

export function MainPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { showStations, orderDetailsClicked, isSesionExpired } = useSelector(
    (state) => state.stations
  )
  const { showDriverMap, isLocationError } = useSelector(
    (state) => state.driverMap
  )
  const { showOrders } = useSelector((state) => state.orders)
  const { userData, token } = useSelector((state) => state.loginOTP)
  const { testLatitude, testLongitude, startDayTime } = useSelector(
    (state) => state.main
  )
  const {
    showRoutes,
    driverCurrentRoute,
    selectedRouteId,
    endDriverSessionSuccess,
  } = useSelector((state) => state.routes)
  const { showScanModal } = useSelector((state) => state.barcodeScanner)
  const [isRefreshRouteBtnDisabled, setRefreshRouteBtnDisabled] =
    useState(false)

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(getPositionSuccess, () =>
      dispatch(setLocationError(true))
    )
  }, [])

  useEffect(() => {
    if (isLocationError) {
      dispatch(
        setCurrentLocation({
          latitude: process.env.REACT_APP_WAREHOUSE_LATITUDE,
          longitude: process.env.REACT_APP_WAREHOUSE_LONGITUDES,
        })
      )
    }
  }, [isLocationError])

  useEffect(() => {
    if (orderDetailsClicked) {
      refreshStationsByRoute()
    }
  }, [orderDetailsClicked])

  useEffect(() => {
    if (userData == null) {
      navigate("/")
    }
  }, userData)

  useEffect(() => {
    if (driverCurrentRoute !== null) {
      const refreshStationsInterval = setInterval(
        refreshStationsByRoute,
        process.env.REACT_APP_ROUTES_REFRESH_INTERVAL
      )
      return () => {
        clearInterval(refreshStationsInterval)
      }
    }
  }, [driverCurrentRoute, selectedRouteId, testLatitude, testLongitude])

  // useEffect(() => {
  //   const getLocationInterval = setInterval(() => {
  //     if (testLatitude && testLongitude) {
  //       dispatch(
  //         setCurrentLocation({
  //           latitude: testLatitude,
  //           longitude: testLongitude,
  //         })
  //       )
  //     } else {
  //       navigator.geolocation.getCurrentPosition(getPosition)
  //     }
  //   }, 10000)
  //   return () => {
  //     clearInterval(getLocationInterval)
  //   }
  // }, [testLatitude, testLongitude])

  useEffect(() => {
    if (endDriverSessionSuccess) {
      dispatch(setShowDriverMap(false))
      dispatch(setShowRoutes(true))
      dispatch(setShowStations(false))
      dispatch(clearEndDriverSessions())
      dispatch(clearStationData())
      dispatch(clearUserData())
      navigate("/")
      window.location.reload(true)
    }
  }, [endDriverSessionSuccess])

  // const getPosition = (position) => {
  //   dispatch(
  //     setCurrentLocation({
  //       latitude: position.coords.latitude,
  //       longitude: position.coords.longitude,
  //     })
  //   )
  // }

  const getPositionSuccess = (position) => {
    dispatch(
      setCurrentLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
    )
    dispatch(setLocationError(false))
  }

  const refreshStationsByRouteClicked = () => {
    setRefreshRouteBtnDisabled(true)
    if (navigator.onLine) {
      refreshStationsByRoute()
    }
    setTimeout(() => setRefreshRouteBtnDisabled(false), 10000)
  }

  const refreshStationsByRoute = () => {
    if (testLatitude && testLongitude) {
      dispatch(
        getStationsByRoute({
          token: token,
          routeId: selectedRouteId,
          useStationRouteCache: true,
          latitude: testLatitude,
          longitude: testLongitude,
          startedDayTime: startDayTime
            ? startDayTime
            : getCurrentTimeUnixFormat(),
        })
      )
    } else {
      getCurrentLocation()
        .then((res) => {
          dispatch(setLocationNotEvailable(false))
          dispatch(
            getStationsByRoute({
              token: token,
              routeId: selectedRouteId,
              useStationRouteCache: true,
              latitude: res.coords.latitude,
              longitude: res.coords.longitude,
              startedDayTime: startDayTime
                ? startDayTime
                : getCurrentTimeUnixFormat(),
            })
          )
        })
        .catch(() => {
          dispatch(setLocationNotEvailable(true))
          console.log("error refresh routes")
        })
    }
  }

  const sessionExpiredHandler = () => {
    dispatch(
      endDriverRouteSessions({
        token: token,
        routeId: selectedRouteId,
      })
    )
  }

  return (
    <div className="mainPage-wrapper ">
      <Header />
      {showDriverMap ? (
        <ButtonWhite
          disabled={isRefreshRouteBtnDisabled}
          extraclass={
            "btn-refresh-routes" +
            (isRefreshRouteBtnDisabled ? " btn-refresh-disabled" : "")
          }
          text="רענן תחנות"
          clickHandler={() => refreshStationsByRouteClicked()}
        />
      ) : null}

      <div
        className={`mainPage-container ${
          showDriverMap ? "vertical" : "horizontal"
        }  `}
      >
        {showRoutes ? <Routes /> : null}
        {showStations ? (
          <Stations />
        ) : (
          <div className="parallax">
            <img src={truck} class="truck" alt="truck"></img>
          </div>
        )}
        {showDriverMap ? <DriverMap /> : null}
        {showOrders ? <Orders /> : null}
        {showScanModal ? <BarcodeScanner /> : null}
        <NotificationPopup
          show={isSesionExpired}
          text={"session expired"}
          close={() => sessionExpiredHandler()}
        ></NotificationPopup>
        <NotificationPopup
          show={isLocationError}
          text={
            <div className="no-location-text">
              <p>
                אין כרגע הרשאות מיקום לדפדפן. זה יגרום למסלול לא להופיע תקין. נא
                לתקן בהגדרות הדפדפן
              </p>
              <p>
                There are currently no location permissions for the browser.
                This will cause the route to not appear correctly. Please
                correct this in your browser settings.
              </p>
            </div>
          }
          close={() => dispatch(setLocationError(false))}
        ></NotificationPopup>
      </div>
    </div>
  )
}
