import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  getRoutes,
  setSelectedRoute,
  setDriverRoute,
  setOnTopSelectedRoute,
} from "./routesSlice"
import { getStationsByRoute, setShowStations } from "../stations/stationsSlice"
import { setShowDriverMap } from "../driverMap/driverMapSlice"
import { setShowRoutes } from "./routesSlice"
import { SingleRoute } from "./singleRoute/singleRoute"
import { ButtonWhite } from "../common/buttonWhite/buttonWhite"
import { setStartDayTime } from "../../pages/mainPage/mainPageSlice.js"
import { Spinner } from "../common/spinner/Spinner.js"
import { getCurrentTimeUnixFormat } from "../../utils/formatDate.js"
import "./routes.scss"

export function Routes() {
  const dispatch = useDispatch()
  const { userData, token, loginSuccess } = useSelector(
    (state) => state.loginOTP
  )
  const { testLatitude, testLongitude } = useSelector((state) => state.main)
  const { stationsList } = useSelector((state) => state.stations)
  const { routes, selectedRouteIndex, selectedRouteId, showRoutes } =
    useSelector((state) => state.routes)
  const {
    stationsWithOrdersCount,
    stationsWithoutOrdersCount,
    loading,
    showStations,
    isFromCache,
    currentRouteIdFromCache,
  } = useSelector((state) => state.stations)
  const { currentLat, currentLong } = useSelector((state) => state.driverMap)

  useEffect(() => {
    if (routes.length > 0) {
      return
    }
    if (loginSuccess && token) {
      dispatch(getRoutes(token))
    }
  }, [loginSuccess])

  useEffect(() => {
    if (selectedRouteIndex) {
      dispatch(setOnTopSelectedRoute())
    }
  }, [selectedRouteIndex, showRoutes])

  const onStationClicked = (props) => {
    if (
      testLatitude &&
      testLongitude &&
      testLatitude != 0 &&
      testLongitude != 0
    ) {
      dispatch(
        setSelectedRoute({
          routeIndex: props.index,
          routeId: props.value,
          routeName: props.label,
        })
      )
      dispatch(
        getStationsByRoute({
          token: token,
          routeId: props.value,
          useStationRouteCache: false,
          latitude: testLatitude,
          longitude: testLongitude,
          startedDayTime: getCurrentTimeUnixFormat(),
          isSetDriverRouteToCache: false,
        })
      )
    } else {
      dispatch(
        setSelectedRoute({
          routeIndex: props.index,
          routeId: props.value,
          routeName: props.label,
        })
      )
      dispatch(
        getStationsByRoute({
          token: token,
          routeId: props.value,
          useStationRouteCache: false,
          latitude: currentLat,
          longitude: currentLong,
          startedDayTime: getCurrentTimeUnixFormat(),
          isSetDriverRouteToCache: false,
        })
      )
    }
    const routesHolder = document.getElementById("routesHolder")
    routesHolder.scrollTop = 0
  }

  const onStartDayClicked = () => {
    dispatch(setStartDayTime())
    if (testLatitude && testLongitude) {
      dispatch(
        getStationsByRoute({
          token: token,
          routeId: selectedRouteId,
          useStationRouteCache: isFromCache ? true : false,
          latitude: testLatitude,
          longitude: testLongitude,
          startedDayTime: getCurrentTimeUnixFormat(),
          isSetDriverRouteToCache: isFromCache ? false : true,
        })
      )
    } else {
      dispatch(
        getStationsByRoute({
          token: token,
          routeId: selectedRouteId,
          useStationRouteCache: isFromCache ? true : false,
          latitude: currentLat,
          longitude: currentLong,
          startedDayTime: getCurrentTimeUnixFormat(),
          isSetDriverRouteToCache: isFromCache ? false : true,
        })
      )
    }

    dispatch(setShowRoutes(false))
    dispatch(setShowStations(true))
    dispatch(setShowDriverMap(true))
    dispatch(setDriverRoute())
  }

  const renderRoutes = () => {
    if (routes === undefined || routes.length === 0) {
      return <span>Loading...</span>
    }
    return routes.map((route, index) => {
      return (
        <SingleRoute
          key={index}
          index={index}
          route={route}
          onStationClicked={(data) => onStationClicked(data)}
          selectedRouteIndex={selectedRouteIndex}
          stationsWithoutOrdersCount={stationsWithoutOrdersCount}
          stationsWithOrdersCount={stationsWithOrdersCount}
          currentRouteIdFromCache={currentRouteIdFromCache}
          loading={loading}
        />
      )
    })
  }

  return (
    <div className="routes-wrapper">
      <div className="routes">
        <div className="welcome">
          <div> שלום, {userData ? userData.firstName : null}</div>
          <div>בחר את מסלול השליחויות שלך להיום:</div>
        </div>
        <div className="routes-holder" id="routesHolder">
          {" "}
          {renderRoutes()}
        </div>

        <div className="btn-start-day">
          <ButtonWhite
            extraclass={
              !selectedRouteId || !stationsList || stationsList.length == 0
                ? "disabled"
                : null
            }
            disabled={
              !selectedRouteId || !stationsList || stationsList.length == 0
                ? "disabled"
                : null
            }
            text={isFromCache ? "המשך במסלול" : "התחל את היום"}
            clickHandler={() => onStartDayClicked()}
          />
        </div>
      </div>
      {showStations ? <div className="grey-space"></div> : null}
      {loading ? <Spinner /> : null}
    </div>
  )
}
