import React from "react"
import { Login } from "./pages/loginPage/login"
import { LoginOTP } from "./pages/loginOtpPage/loginOTP"
import { MainPage } from "./pages/mainPage/mainPage"
import "./App.css"
import { BrowserRouter, Routes, Route } from "react-router"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginOTP />} />
          <Route path="/MainPage" element={<MainPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
